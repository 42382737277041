<template>
  <div class="orders">
    <div class="orders-title">
      <p class="title">订单管理</p>
    </div>
    <div class="table-menu">
      <div class="table-menu-left">
        <a-input-search
          style="width: 300px;"
          placeholder="请输入订单关键字"
          @search="onSearch"
          v-model="keyword"
          enterButton="查找"
          >查找</a-input-search
        >
        <a-button
          class="my-button"
          type="primary"
          style="margin-left:20px"
          @click="exportInformation"
        >
          <a-icon type="vertical-align-bottom" />订单信息导出
        </a-button>
      </div>
      <div class="table-menu-right">
        <span class="senior-search" @click="senoirSearch" v-if="!isFlag"
          >高级搜索</span
        >
      </div>
    </div>
    <button-search
      :depNames="depNames"
      :dutyNames="dutyNames"
      :positionNames="positionNames"
      :schoolList="schoolList"
      :orderStatus="orderStatus"
      :payMethods="payMethods"
      :storeLists="storeLists"
      :refundOrder="refundOrder"
      :isFlag="isFlag"
      :isOrder="true"
      :isToS="false"
      @putAway="senoirSearch"
      @seniorSearch="getSearchList"
      @clear="clear"
      @changeField="changeField"
    ></button-search>
    <p class="total">共{{ orderCount }}条</p>
    <div class="orders-box">
      <div class="data-title">
        <table class="table-sf">
          <tbody>
            <tr class="weight">
              <td>订单详情</td>
              <td class="number">数量</td>
              <td class="person">收货人</td>
              <td class="person">购买人</td>
              <td class="total">总金额</td>
              <td class="total">支付方式</td>
              <td class="status">状态</td>
              <td class="btns">操作</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="data-listbox">
        <div class="spin" v-if="!loading">
          <a-spin />
        </div>
        <table
          v-else
          class="table-sf font12"
          v-for="(order, index) in list"
          :key="index"
        >
          <tbody>
            <tr>
              <th colspan="9">
                <!-- | moment('YYYY-MM-DD HH:mm') -->
                <span>{{
                  moment(order.createTime).format("YYYY-MM-DD HH:mm:ss")
                }}</span>
                <span class="ml30">
                  订单号：
                  <router-link :to="`/orderState/${order.id}`">
                    <span>{{ order.id }}</span>
                  </router-link>
                </span>
                <!-- <span class="ml30">
                  商户订单号：
                    <span>{{order.paymentBillId ? order.paymentBillId : '暂无'}}</span>
                </span> -->
                <span style="margin-left: 25px;">
                  <a-tag
                    v-show="order.returns && order.returnsAudit === 100"
                    color="red"
                    style="cursor:default;"
                    >退货成功</a-tag
                  >
                  <a-tag
                    v-show="order.returns && order.returnsAudit === 0"
                    color="#108ee9"
                    style="cursor:default;"
                    >退货审核中</a-tag
                  >
                  <a-tag
                    v-show="order.returns && order.returnsAudit === 50"
                    color="#f50"
                    style="cursor:default;"
                    >待超管审核</a-tag
                  >
                  <a-tag
                    v-show="order.returns && order.returnsAudit === -100"
                    color="#ccc"
                    style="cursor:default;"
                    >退货失败</a-tag
                  >
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>{{ order.returnsRemark }}</span
                      ><br />
                      <span>{{ order.returnsInfo }}</span
                      ><br />
                      <img :src="getPic(order.returnsPic)" alt="" />
                    </template>
                    <a-icon
                      class="ml5"
                      style="font-size: 16px;cursor: pointer;"
                      v-show="order.returnsInfo && order.returnsRemark"
                      type="question-circle"
                    />
                  </a-tooltip>
                </span>

                <span class="ml30">
                  店铺：
                  <span
                    style=" width: 100%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;color:#e69b47"
                    >{{ order.storeName }}</span
                  >
                </span>
                <!--
                <span class="ml30">
                  商城：
                  <span
                    style=" width: 100%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                  >{{order.mallName}}</span>
                </span>-->
              </th>
            </tr>
            <tr class="weight">
              <td class="info-box" colspan="3">
                <table
                  class="item-info"
                  v-for="(product, index) in order.products"
                  :key="index"
                >
                  <tbody>
                    <tr>
                      <td class="imgbox">
                        <div>
                          <div                             
                            @click="()=>{
                              previewVisible = true;
                              previewImage = getPhoto(product.picDef);
                              }" 
                              class="picCoverBox"  
                              v-if="product.picDef"
                              >
                          <div class="picCover">
                            <a-icon type="zoom-in" />
                          </div>
                          <img
                            :src="getPhoto(product.picDef)"
                          />
                          <a-modal
                            :visible="previewVisible"
                            :footer="null"
                            @cancel="previewVisible = false"
                          >
                            <img
                              alt="example"
                              style="width: 100%"
                              :src="previewImage"
                            />
                          </a-modal>
                         </div>
                          <img v-else src="../../assets/imgs/demoimg.png" />
                        </div>
                      </td>
                      <td class="align-l">
                        <!-- <router-link :to="`/preview/${product.sku}`" target="_blank">
                          <span class="color5">{{product.name}}</span>
                        </router-link> -->
                        <span class="color5">{{ product.name }}</span>
                        <div>
                          (规格:
                          <span class="sku">{{
                            product.productPropertyList[0].value
                          }}</span
                          >)
                        </div>
                      </td>
                      <td class="price-s">
                        ¥
                        <span>{{ numberFormat(product.unitPrice) }}</span>
                        <!-- /
                        <span>{{product.unit}}</span>-->
                      </td>
                      <td class="number-s">
                        <span>{{ product.num }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <!-- 收货人 -->
              <td class="person">
                <div>
                  {{ order.consigneeName }}
                  <div class="msg-f dp-hide">
                    <p>姓名：{{ order.consigneeName }}</p>
                    <p>地址：{{ order.consigneeAddress }}</p>
                    <p>电话：{{ order.consigneePhone }}</p>
                  </div>
                </div>
              </td>
              <!-- 购买人 -->
              <td class="person">
                <div>
                  {{ order.shopUserName }}
                  <div class="msg-f dp-hide">
                    <p v-if="order.shopUserSchoolName">
                      单位：{{ order.shopUserSchoolName }}
                    </p>
                    <p v-if="order.shopUserSchoolDepName">
                      部门：{{ order.shopUserSchoolDepName }}
                    </p>
                    <p v-if="order.shopUserSchoolPositionName">
                      职位：{{ order.shopUserSchoolPositionName }}
                    </p>
                    <p v-if="order.shopUserSchoolDutyName">
                      职务：{{ order.shopUserSchoolDutyName }}
                    </p>
                  </div>
                </div>
              </td>
              <td class="total colorRed">
                {{ numberFormat(order.totalPrice) }}<br />
                <p>含{{ order.transportPrice }}元运费</p>
              </td>
              <td class="total">
                {{
                  order.payTypeCode === "onlinePayment"
                    ? "在线支付"
                    : "线下付款"
                }}
              </td>
              <td class="status">
                <div class="div-relative">
                  <!--<i class="warning"></i>-->
                  <a-tag v-show="order.state == 'CANCEL'">已取消</a-tag>
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>{{ order.cancelRemark }}</span>
                    </template>
                    <a-icon
                      class="ml5"
                      style="font-size: 16px;cursor: pointer;"
                      v-show="order.state == 'CANCEL' && order.cancelRemark"
                      type="question-circle"
                    />
                  </a-tooltip>
                  <a-tag
                    v-show="
                      order.payTypeCode === 'onlinePayment' &&
                        order.state == 'SHOP_AUDIT' &&
                        !order.payment
                    "
                    color="#f25c5c"
                    >待支付</a-tag
                  >
                  <a-tag
                    v-show="
                      order.payTypeCode === 'onlinePayment' &&
                        order.state == 'PAYMENT' &&
                        order.payment
                    "
                    color="blue"
                    >支付完成</a-tag
                  >
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>{{ order.paymentRemark }}</span>
                    </template>
                    <a-icon
                      class="ml5"
                      style="font-size: 16px;cursor: pointer;"
                      v-show="
                        order.payTypeCode === 'onlinePayment' &&
                          order.state == 'PAYMENT' &&
                          order.paymentRemark
                      "
                      type="question-circle"
                    />
                  </a-tooltip>
                  <!-- 如果是线下付款，审核过后不应该展示支付完成，而是审核通过 -->
                  <a-tag
                    v-show="
                      order.payTypeCode === 'deliveryPayment' &&
                        order.state == 'PAYMENT'
                    "
                    color="blue"
                    >审核通过</a-tag
                  >
                  <a-tag v-show="order.state == 'DELIVERY'" color="blue"
                    >已发货</a-tag
                  >
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>{{ order.deliveryRemark }}</span>
                    </template>
                    <a-icon
                      class="ml5"
                      style="font-size: 16px;cursor: pointer;"
                      v-show="order.state == 'DELIVERY' && order.deliveryRemark"
                      type="question-circle"
                    />
                  </a-tooltip>
                  <a-tag v-show="order.state == 'RECEIVED'" color="blue"
                    >收货</a-tag
                  >
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span
                        >{{ order.deliveryRemark }}<br />{{
                          order.receivedRemark
                        }}</span
                      >
                    </template>
                    <a-icon
                      class="ml5"
                      style="font-size: 16px;cursor: pointer;"
                      v-show="order.state == 'RECEIVED' && order.receivedRemark"
                      type="question-circle"
                    />
                  </a-tooltip>
                  <a-tag v-show="order.state == 'FINISH'" color="blue"
                    >完成</a-tag
                  >
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span
                        >{{ order.deliveryRemark }}<br />{{
                          order.receivedRemark
                        }}</span
                      >
                    </template>
                    <a-icon
                      class="ml5"
                      style="font-size: 16px;cursor: pointer;"
                      v-show="order.state == 'FINISH' && order.receivedRemark"
                      type="question-circle"
                    />
                  </a-tooltip>
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>{{ order.finishRemark }}</span>
                    </template>
                    <a-icon
                      class="ml5"
                      style="font-size: 16px;cursor: pointer;"
                      v-show="order.state == 'FINISH' && order.finishRemark"
                      type="question-circle"
                    />
                  </a-tooltip>
                  <a-tag color="green" v-show="order.state == 'NEW'"
                    >新订单</a-tag
                  >
                </div>
              </td>
              <td class="btns">
                <div
                  v-show="order.state == 'NEW'"
                  style="margin-bottom: 1.5px;"
                >
                  <a-button
                    size="small"
                    type="primary"
                    @click="showModal(order.id, 0, 0)"
                    >审核订单</a-button
                  >
                  <!-- <a-button size="small" @click="showCancelModal(order.id,order)">取消订单</a-button> -->
                  <!-- <a-button size="small" @click="reject(order)">取消订单</a-button> -->
                </div>
                <!--  <div v-show="order.state == 'NEW' || order.state == 'SHOP_AUDIT'" style="margin-bottom: 1.5px;">
                  <a-button size="small" @click="showCancelModal(order.id, order)">取消订单</a-button>
                </div> -->
                <!-- <div v-show="order.returns && order.state !== 'CANCEL'" style="margin-bottom: 1.5px;">
                  <a-button size="small" @click="showModal(order.id, 5, 1)">退款审核</a-button>
                </div>
                <div
                  v-show="order.state == 'PAYMENT' || (order.payTypeCode === 'deliveryPayment' && order.state === 'SHOP_AUDIT')"
                  style="margin-bottom: 1.5px;"
                >
                  <a-button size="small" @click="showModal(order.id, 4)">发货</a-button>
                </div>
                -->
                <div
                  v-show="
                    order.payTypeCode === 'deliveryPayment' &&
                      order.state != 'NEW' &&
                      order.state != 'PAYMENT' &&
                      order.state != 'CANCEL'
                  "
                  style="margin-bottom: 1.5px;"
                >
                  <a-button
                    v-show="!order.payment"
                    size="small"
                    @click="surePayment(order.id)"
                    >确认付款</a-button
                  >
                  <a-button
                    size="small"
                    v-show="!order.invoice"
                    @click="showModal(order.id, 3)"
                    >确认开发票</a-button
                  >
                </div>
                <div>
                  <router-link
                    v-if="order.state == 'FINISH'"
                    :to="`/orderDetail/${order.id}`"
                    >查看详情</router-link
                  >
                </div>
                <!-- <a-button type="primary" size="small" v-show="order.status == 'ADOPT' || order.status == 'PAYED'">
                  <router-link :to="`/order/${order.id}/express`">发货</router-link>
                </a-button> -->
                <!-- <router-link v-show="order.express==true" :to="`/order/${order.id}/express`">查看详情</router-link>
                <a v-show="order.evaluated==true">查看评价</a> -->
              </td>
              <!-- <td class="paymentRemark">{{order.paymentRemark}}</td> -->
            </tr>
          </tbody>
        </table>
        <div class="align-c" v-if="list.length == 0" style="padding:50px 0;">
          您暂时还没有相关订单~
        </div>
      </div>
      <!-- 审核模态框  -->
      <a-modal
        title="审核订单"
        :visible="visible"
        :confirmLoading="confirmLoading"
        :footer="null"
        @cancel="handleCancel(0)"
      >
        <template>
          <a-textarea
            placeholder="若驳回，请输入理由"
            v-model="text"
            :rows="4"
          />
        </template>
        <div
          style="
          display: flex;
          justify-content: flex-end;
          margin-top: 10px;
        "
        >
          <a-button type="primary" @click="agree(0)">同意</a-button>
          <a-button style="margin-left:20px" @click="refuse(0)">驳回</a-button>
        </div>
      </a-modal>
      <!-- 退款审核模态框  -->
      <a-modal
        title="退货审核"
        :visible="visible5"
        :confirmLoading="confirmLoading"
        :footer="null"
        @cancel="handleCancel(5)"
      >
        <template>
          <a-textarea
            placeholder="若驳回，请输入驳回理由"
            v-model="text"
            :rows="4"
          />
        </template>
        <div
          style="
          display: flex;
          justify-content: flex-end;
          margin-top: 10px;
        "
        >
          <a-button type="primary" @click="agree(1)">同意</a-button>
          <a-button type="primary" style="margin-left:20px" @click="refuse(1)"
            >驳回</a-button
          >
        </div>
      </a-modal>
      <!-- 确认支付  -->
      <a-modal
        title="确认付款"
        :visible="visible2"
        :confirmLoading="confirmLoading"
        :footer="null"
        @cancel="handleCancel(2)"
      >
        <div
          style="
          display: flex;
          justify-content: flex-end;
          margin-top: 10px;
        "
        >
          <a-button type="primary" @click="handleIsPayment">确认</a-button>
          <a-button
            type="primary"
            style="margin-left:20px"
            @click="handleCancel(2)"
            >取消</a-button
          >
        </div>
      </a-modal>
      <!-- 确认发票  -->
      <a-modal
        title="确认开发票"
        :visible="visible3"
        :confirmLoading="confirmLoading"
        :footer="null"
        @cancel="handleCancel(3)"
      >
        <template>
          <a-textarea
            placeholder="请填写开票信息备注（选填）"
            v-model="InvoiceCause"
            :rows="4"
          />
        </template>
        <div
          style="
          display: flex;
          justify-content: flex-end;
          margin-top: 10px;
        "
        >
          <a-button type="primary" @click="handleInvoice">确认</a-button>
          <a-button
            type="primary"
            style="margin-left:20px"
            @click="handleCancel(3)"
            >取消</a-button
          >
        </div>
      </a-modal>
      <!-- 确认发货  -->
      <a-modal
        title="确认发货"
        :visible="visible4"
        :confirmLoading="confirmLoading"
        :footer="null"
        @cancel="handleCancel(4)"
      >
        <div class="modal-input">
          <label style="width:85px">快递单号：</label>
          <a-input
            placeholder="请输入快递单号"
            v-model="logistics.logisticsNo"
          />
        </div>
        <div class="modal-input">
          <label style="width:85px">快递公司：</label>
          <a-input
            placeholder="请输入快递公司"
            v-model="logistics.logisticsCompany"
          />
        </div>
        <div
          style="
          display: flex;
          justify-content: flex-end;
          margin-top: 10px;
        "
        >
          <a-button type="primary" @click="handleSendGoods">确认</a-button>
          <a-button
            type="primary"
            style="margin-left:20px"
            @click="handleCancel(4)"
            >取消</a-button
          >
        </div>
      </a-modal>
      <!-- 取消模态框 -->
      <a-modal
        title="取消订单"
        :visible="visible1"
        @ok="handleOk"
        :confirmLoading="confirmLoading"
        @cancel="handleCancel(1)"
        okText="同意"
        cancelText="取消"
      >
        <div>
          <div class="modal-input">
            <span style="color:red;">*</span>
            <label for>订单号：</label>
            <template>
              <a-input v-model="no" disabled />
            </template>
          </div>
          <div class="modal-input">
            <span style="color:red;">*</span>
            <label for>采购人：</label>
            <template>
              <a-input v-model="shopUserName" disabled />
            </template>
          </div>
          <div class="modal-input">
            <span style="color:red;">*</span>
            <label for>原因：</label>
            <template>
              <a-textarea
                placeholder="请详细填写退款原因"
                v-model="cause"
                :rows="4"
              />
            </template>
          </div>
        </div>
      </a-modal>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <a-pagination
        v-model="current"
        :total="orderCount"
        :defaultPageSize="20"
        @change="paginationChange"
      />
    </div>
  </div>
</template>

<script>
import {
  getOrderList,
  isAgree,
  invoiceInfo,
  isPayment,
  getOrderLength,
  getStoreInfo
} from "../../serve/order";
import { getStoreLength, getStoreList } from "../../serve/product";
import global from "global";
import { mixinFormat } from "@/mixins/mixin";
import buttonSearch from "@/components/button-search/buttonSearch";
import moment from "moment";
import { advancedScreen } from "@/mixins/advancedScreen";
import { mapActions } from "vuex";

export default {
  mixins: [mixinFormat, advancedScreen],
  components: {
    buttonSearch
  },
  data() {
    return {
      id: "",
      myList: {},
      schoolList: [], // 学校列表
      text: "", // 审核
      no: "",
      isFlag: false,
      loading: false,
      shopUserName: "", // 采购人
      cause: "",
      visible: false,
      visible1: false, // 1
      visible5: false, // 退款审核
      visible2: false, // 确认支付
      visible3: false, // 发票
      visible4: false, // 发货
      confirmLoading: false,
      current: 1,
      keyword: "",
      list: [],
      value: 1, // 确认付款
      InvoiceCause: "", // 发票备注
      // 快递单号及公司
      logistics: {
        logisticsCompany: "",
        logisticsNo: ""
      },
      isR: 0, // 判断是否是退款还是订单审核
      orderCount: 0, // 订单条数
      params: {
        page: 0,
        rows: 20,
        keyword: "",
        state: "",
        sort: "create_time desc",
        startDate: "",
        endDate: "",
        payTypeCode: "",
        depName: "",
        dutyName: "",
        positionName: "",
        storeId: "",
        returns: "",
        returnsAudit: "",
        showNoPay:false
      },
      userInfo: {},
      name: "order",
      storeLists: [], // 供应商列表
      previewVisible:false,
      previewImage:''
    };
  },
  methods: {
    ...mapActions(["checkReview"]),
    moment,
    // 订单信息导出
    exportInformation() {
      let token = window.sessionStorage.getItem("school_token");
      let url =
        global.file.url +
        "/school/orders/export?access_token=" +
        token +
        "&state=" +
        this.params.state +
        "&startDate=" +
        this.params.startDate +
        "&endDate=" +
        this.params.endDate +
        "&payTypeCode=" +
        this.params.payTypeCode +
        "&depName=" +
        this.params.depName +
        "&dutyName=" +
        this.params.dutyName +
        "&positionName=" +
        this.params.positionName +
        "&keyword=" +
        this.params.keyword +
        "&storeId=" +
        this.params.storeId +
        "&returns=" +
        this.params.returns +
        "&returnsAudit=" +
        this.params.returnsAudit +
        '&showNoPay=' +
        this.params.showNoPay;
      console.log("url", url);
      window.open(url);
    },
    // 更改字段
    changeField(field, value) {
      this.params[field] = value;
    },
    clear(params) {
      this.params = params;
      this.current = 1;
      this.getSearchList(this.params);
    },
    // 高级列表搜索
    async getSearchList(params) {
      this.loading = false;
      this.params = params;
      this.current = 1;
      this.orderCount = await getOrderLength(params);
      getOrderList(params).then(res => {
        for (let i = 0; i < res.length; i++) {
          let totalPrice = 0; // 商品总价，不加运费
          for (let j = 0; j < res[i].products.length; j++) {
            totalPrice += res[i].products[j].unitPrice * res[i].products[j].num;
          }
          res[i].totalPrice = totalPrice + res[i].transportPrice;
        }
        this.list = res;
        this.loading = true;
      });
    },
    // 获取照片
    getPhoto(id) {
      return global.file.url + "/sys/fs/" + id + "?s=4";
    },
    // 获取退款原因图片
    getPic(opt) {
      return global.file.url + "/sys/fs/" + opt + "?s=4";
    },
    async getData() {
      this.loading = false;
      this.orderCount = await getOrderLength(this.params);
      getOrderList(this.params).then(res => {
        for (let i = 0; i < res.length; i++) {
          let totalPrice = 0; // 商品总价，不加运费
          for (let j = 0; j < res[i].products.length; j++) {
            totalPrice += res[i].products[j].unitPrice * res[i].products[j].num;
          }
          res[i].totalPrice = totalPrice + res[i].transportPrice;
        }
        this.list = res;
        this.loading = true;
      });
      this.storeLists = await getStoreList({
        page: 0,
        rows: 20
      });
      this.storeLists.unshift({
        id: "",
        name: "全部"
      });
    },
    // 成功提示
    success() {
      this.$message.info("操作成功");
    },
    fail(info) {
      this.$message.info("操作失败," + info);
    },
    // 分页
    paginationChange(page) {
      this.params.page = page - 1;
      this.getData();
    },
    onSearch(val) {
      // console.log(val)
      this.params.page = 0;
      this.params.keyword = val;
      this.getData();
    },
    handleChange(val) {
      this.params.state = val;
      this.getData();
    },
    surePayment(id) {
      let that = this;
      this.$confirm({
        title: "提示",
        content: "确认订单支付完成？",
        okText: "确定",
        cancelText: "取消",
        onOk() {
          isPayment(id)
            .then(res => {
              that.success();
              that.getData();
            })
            .catch(error => {
              that.fail(error.data.message);
            });
        },
        onCancel() {}
      });
    },
    // 确认支付
    handleIsPayment() {
      let data = {};
      data.remark = this.value;
      isPayment(this.id, data)
        .then(res => {
          this.success();
          this.getData();
        })
        .catch(error => {
          this.fail(error.data.message);
        });
      this.visible2 = false;
    },
    handleSendGoods() {
      if (
        this.logistics.logisticsCompany.trim() === "" ||
        this.logistics.logisticsNo.trim() === ""
      ) {
        return this.fail("请输入信息");
      }
      let data = this.logistics;
      sendGoods(this.id, data)
        .then(res => {
          this.success();
          this.getData();
        })
        .catch(error => {
          this.fail(error.data.message);
          this.getData();
        });
      this.visible4 = false;
    },
    // 确认开发票
    handleInvoice() {
      let data = {};
      data.remark = this.InvoiceCause;
      invoiceInfo(this.id, data)
        .then(res => {
          this.success();
          this.getData();
        })
        .catch(error => {
          this.fail(error.data.message);
          this.getData();
        });
      this.visible3 = false;
    },
    showCancelModal(id, data) {
      this.id = id;
      this.no = this.id;
      this.shopUserName = data.shopUserName;
      this.visible1 = true;
    },
    showModal(id, index, isR) {
      this.isR = isR;
      this.id = id;
      switch (index) {
        case 0:
          this.visible = true;
          break;
        case 5:
          this.visible5 = true;
          break;
        case 2:
          this.visible2 = true;
          break;
        case 3:
          this.visible3 = true;
          break;
        case 4:
          this.visible4 = true;
      }
    },
    showModal1(id) {
      // console.log(data.id)
      this.id = id;
      // this.no = data.id
      // this.Purchaser = data.mallName
      this.visible1 = true;
    },
    agree(index) {
      // 0 是订单审核 1是退款审核
      let data = {};
      data.audit = 1;
      data.remark = this.text;
      if (index === 0) {
        isAgree(this.id, data)
          .then(res => {
            this.success();
            this.getData();
            this.checkReview();
            this.visible = false;
          })
          .catch(error => {
            this.fail(error.data.message);
            this.visible = false;
          });
      } else if (index === 1) {
        /*  isRefund(this.id, data)
                  .then(res => {
                    this.success()
                    this.getData()
                    this.visible5 = false
                  })
                  .catch(error => {
                    this.fail(error.data.message)
                    this.visible5 = false
                  }) */
      }
    },
    refuse(index) {
      if (this.text === "") {
        alert("驳回，请输入驳回理由");
        return false;
      }
      let data = {};
      data.audit = -1;
      data.remark = this.text;
      if (index === 0) {
        isAgree(this.id, data)
          .then(res => {
            this.success();
            this.getData();
            this.checkReview();
            this.visible = false;
          })
          .catch(error => {
            this.fail(error.data.message);
            this.visible = false;
          });
      } else if (index === 1) {
        /*  isRefund(this.id, data)
                  .then(res => {
                    this.success()
                    this.getData()
                    this.visible5 = false
                  })
                  .catch(error => {
                    this.fail(error.data.message)
                    this.visible5 = false
                  }) */
      }
    },
    handleOk() {
      let data = {};
      data.remark = this.cause;
      cancelOrder(this.id, data)
        .then(res => {
          this.success();
          this.getData();
        })
        .catch(error => {
          this.fail(error.data.message);
        });
      this.visible1 = false;
    },
    handleCancel(index) {
      switch (index) {
        case 0:
          this.visible = false;
          break;
        case 1:
          let data = {};
          data.remark = this.cause;
          cancelOrder(this.id, data)
            .then(res => {
              this.success();
              this.getData();
            })
            .catch(error => {
              this.fail(error.data.message);
            });
          this.visible1 = false;
          break;
        case 2:
          this.visible2 = false;
          break;
        case 3:
          this.visible3 = false;
          break;
        case 5:
          this.visible5 = false;
          break;
        case 4:
          this.visible4 = false;
      }
    }
  },
  created() {
    this.getData();
    this.userInfo = JSON.parse(sessionStorage.getItem("school_userInfo"));
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.query.underReview) {
          this.params.state = "NEW";
          this.params.shopNeedAudit = true;
        } else {
          this.params.state = "";
          this.params.shopNeedAudit = "";
        }
        this.getData();
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
<style lang="less" scoped>
/deep/ .ant-modal-mask{
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
<style lang="less">
// .weight {
//   display: flex;
//   justify-content: space-between
// }
// .orders-title {
//   .title {
//     font-weight: 600;
//     color: black;
//     font-size: 15px;
//     margin: 10px 0 10px 10px;
//   }
//   .total {
//     text-align: right;
//     margin: 10px 10px 10px 10px;
//   }
// }
.table-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 20px 20px;

  .table-menu-right {
    // height: 32px;
    .senior-search {
      color: #e69b47;
      width: 80px;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        color: #f38e1c;
      }
    }

    .my-input {
      height: 32px !important;
    }
  }
}

.orders-box {
  border: 1px solid #eee;
}

.orders-box .table-sf td {
  text-align: center;
  vertical-align: middle;
}

.data-listbox {
  .spin {
    text-align: center;
    padding-top: 20px;
  }

  th {
    color: #666;
    line-height: 31px;
    background: #f4f8fa;
    padding: 0 10px;
  }
}

.table-sf {
  width: 100%;
  border-collapse: collapse;
}

.table-sf th,
.table-sf td {
  border: 1px solid #eee;
  border-bottom: none;
}

.data-title {
  line-height: 40px;
  background: #f8f8f8;

  .table-sf {
    td {
      border: none;
    }
  }
}

.orders-box .price {
  width: 90px;
}

.orders-box .number {
  width: 42px;
}

.orders-box .person {
  width: 80px;
}

.orders-box .total {
  width: 90px;
}

.orders-box .status {
  width: 100px;
}

.orders-box .btns {
  width: 100px;
}

.orders-box .btns a {
  display: block;
  line-height: 24px;
}

.orders-box .btns a.default {
  color: #666;
}

.orders-box .btns a.default:hover {
  text-decoration: underline;
}

.orders-box .status > div,
.orders-box .person > div {
  position: relative;
}

.orders-box .status > div:hover .msg-f,
.orders-box .person > div:hover .msg-f {
  display: block;
  z-index: 99;
}

.status .msg-f {
  width: 140px;
  line-height: 28px;
  padding: 2px 10px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 26px;
  left: 50%;
  margin-left: -71px;
  z-index: 99;
}

.status .msg-f:before {
  content: "";
  position: absolute;
  top: -9px;
  left: 65px;
  display: inline-block;
  border-right: 9px solid transparent;
  border-bottom: 9px solid #ddd;
  border-left: 9px solid transparent;
}

.status .msg-f:after {
  content: "";
  position: absolute;
  top: -8px;
  left: 65px;
  display: inline-block;
  border-right: 9px solid transparent;
  border-bottom: 9px solid #fff;
  border-left: 9px solid transparent;
}

.orders-box .person .msg-f {
  width: 220px;
  text-align: left;
  padding: 10px 20px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: -18px;
  left: -220px;
}

.orders-box .person .msg-f:before {
  content: "";
  position: absolute;
  top: 19px;
  right: -10px;
  display: inline-block;
  border-top: 9px solid transparent;
  border-left: 9px solid #ddd;
  border-bottom: 9px solid transparent;
}

.orders-box .person .msg-f:after {
  content: "";
  position: absolute;
  top: 19px;
  right: -9px;
  display: inline-block;
  border-top: 9px solid transparent;
  border-left: 9px solid #fff;
  border-bottom: 9px solid transparent;
}

.orders-box .person .msg-f p {
  margin-bottom: 1em;
}

.orders-box .info-box {
  padding: 0;

  .sku {
    color: #e69b47;
  }
}

.orders-box .item-info,
.orders-box .item-info + .item-info {
  width: 100%;
  position: relative;
  margin-bottom: -1px;
  border-top: 2px solid #eee;
}

.orders-box .item-info td {
  padding: 10px 0;
  border: 1px solid transparent;
}

.orders-box .item-info .imgbox {
  width: 90px;
}

.orders-box .item-info .imgbox div {
  margin: 0 15px;
  position: relative;
}

.orders-box .item-info .imgbox img {
  display: block;
  width: 58px;
  height: 58px;
}

.orders-box .item-info .imgbox :hover{
  .picCover{
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;
  }
}
.picCoverBox{
  width: 58px;
  height: 58px;
  position: relative;
  cursor: pointer;
}
.orders-box .item-info .imgbox .picCover{
  width: 58px;
  height: 58px;
  margin: 0;
  position: absolute;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.45);
  display: none;
  color: #fff;
}

.orders-box .item-info .type,
.danger-type {
  display: inline-block;
  line-height: 16px;
  color: #f78716;
  background: #fff9f9;
  border: 1px solid #f78716;
  border-radius: 2px;
  padding: 0 4px;
  margin-right: 5px;
}

.orders-box .item-info .name a {
  color: #333;
}

.orders-box .item-info .name a:hover {
  text-decoration: underline;
}

.orders-box .item-info .price-s {
  width: 124px;
}

.orders-box .item-info .number-s {
  width: 78px;
}

.orders-box .ant-tag {
  margin-right: 0;
}

// 模态框
.modal-input {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  label {
    width: 60px;
  }
}

// 分页
.pagination {
  display: flex;
  justify-content: flex-end;
  margin: 10px;
}
</style>
